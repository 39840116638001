import api from "../axios_service.js";

export async function all(filtros) {
  const response = await api.get(`/assuntos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function one(id) {
  const response = await api.get(`/gestor/assuntos/${id}}`);
  return response.data.result;
}

export function postAssunto(cargo) {
  return api.post("/gestor/assuntos/add", cargo);
}

export function putAssunto(id, cargo) {
  return api.put(`/gestor/assuntos/${id}`, cargo);
}
