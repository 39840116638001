<template>
  <v-dialog
    v-model="dialogAssuntos"
    @click:outside="$emit('update:dialogAssuntos', false)"
    @keydown.esc="$emit('update:dialogAssuntos', false)"
    max-width="750px"
  >
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        Assuntos
      </v-card-title>
      <v-divider></v-divider>
      <div
        class="px-4 pt-4 d-block d-sm-flex align-center justify-space-between"
      >
        <v-text-field
          style="max-width: 200px;"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-form
          @submit.prevent
          class="d-flex"
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <div class="d-flex mt-4 mt-sm-0">
            <v-text-field
              v-if="isAdding"
              v-model="assunto.assunto"
              label="Assunto"
              dense
              hide-details
              class="pr-4"
              ref="assuntos"
              :rules="formRules"
              required
            ></v-text-field>
            <v-btn
              v-if="!isAdding"
              color="secondary"
              class="white--text"
              @click="isAdding = !isAdding"
            >
              <v-icon left> mdi-plus</v-icon>
              Adicionar
            </v-btn>
            <v-btn
              v-if="isAdding"
              color="secondary"
              class="white--text"
              @click="createAssunto"
              :disabled="!validForm"
            >
              <v-icon left> mdi-check</v-icon>
              Salvar
            </v-btn>
          </div>
        </v-form>
      </div>
      <v-card-text class="py-5">
        <v-data-table
          :headers="headers"
          :search="search"
          :items="assuntos"
          :items-per-page="10"
          disable-sort
          class="data-tables data-tables__row-click"
        >
          <template v-slot:item.assunto="{ item, index }">
            <v-text-field
              v-if="editItem === index && isEditing"
              v-model="assunto_editar.assunto"
              dense
              hide-details
              :rules="formRules"
              required
              class="mb-2"
            ></v-text-field>
            <div v-else>{{ item.assunto }}</div>
          </template>

          <template v-slot:item.status="{ item, index }">
            <div class="d-flex align-center justify-center">
              <v-switch
                v-if="isEditing && index === editItem"
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                v-model="assunto_editar.status"
              ></v-switch>
              <v-switch
                v-else
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                disabled
                v-model="item.status"
              ></v-switch>
            </div>
          </template>

          <template v-slot:item.editar="{ item, index }">
            <!-- Editar -->
            <v-btn
              v-if="!isEditing || editItem != index"
              small
              icon
              class=""
              @click="editRow(item, index)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <div class="d-flex align-center justify-center">
              <!-- Salvar -->
              <v-btn
                v-if="isEditing && editItem === index"
                small
                icon
                class=""
                @click="updateAssunto(item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>

              <!-- Cancelar -->
              <v-btn
                v-if="isEditing && editItem === index"
                small
                icon
                class="ml-1"
                @click="closeEditing(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  all as getAssuntos,
  postAssunto,
  putAssunto,
} from "@/api/gestor/assuntos.js";
import { inputRequired } from "@/helpers/utils.js";
export default {
  name: "DialogAssuntos",

  props: {
    dialogAssuntos: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      assuntos: [],
      search: "",
      isAdding: false,
      isEditing: false,
      validForm: true,
      assunto_editar: {},
      formRules: [inputRequired],
      assunto: {},
      editItem: null,
      itemOriginal: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Assunto",
          value: "assunto",
        },
        {
          text: "Status",
          sortable: false,
          width: "80px",
          align: "center",
          value: "status",
        },
        {
          text: "Editar",
          sortable: false,
          width: "80px",
          align: "center",
          value: "editar",
        },
      ];
    },
  },

  methods: {
    editRow(item, index) {
      this.itemOriginal = { ...item };
      this.assunto_editar = { ...item };
      this.isEditing = true;
      this.isAdding = false;
      this.editItem = index;
    },
    fetchAssuntos() {
      this.loading = true;
      getAssuntos()
        .then((response) => {
          this.assuntos = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    createAssunto() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.assunto.status = 1;
        postAssunto(this.assunto)
          .then(() => {
            this.fetchAssuntos();
            this.isAdding = false;
            this.assunto.assunto = null;
          })
          .catch(() => {
            this.$toast.error("Assunto já existente!");
            this.$refs.assuntos.reset();
            this.$refs.assuntos.focus();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateAssunto() {
      if (
        JSON.stringify(this.itemOriginal) ===
        JSON.stringify(this.assunto_editar)
      ) {
        this.$toast.error("Por favor faça uma modificação antes de salvar");
        return;
      }
      if (
        this.assunto_editar.assunto === null ||
        this.assunto_editar.assunto === "" ||
        this.assunto_editar.assunto === undefined
      ) {
        this.$toast.error("Por favor preencha o campo com o assunto!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        putAssunto(this.assunto_editar.id, this.assunto_editar)
          .then(() => {
            this.loading = false;
            this.isEditing = false;
            this.editItem = null;
            this.fetchAssuntos();
          })
          .catch(() => {
            this.$toast.error("Assunto já existente!");
          });
      }
    },

    closeEditing(item) {
      this.itemOriginal = { ...item };
      this.isEditing = false;
      this.editItem = null;
    },
  },

  mounted() {
    this.fetchAssuntos();
  },
};
</script>

<style></style>
